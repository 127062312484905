<script>
import { required, email } from "vuelidate/lib/validators";
//import HttpRequest from "@/http/HttpRequest"

import Vue from 'vue';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

//Common HTTP object for managing request response
//let httpRequest = new HttpRequest()

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      sentEmail: ""
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.sentEmail = this.$route.query.id
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    gotoLogIn(){
      this.$router.push({path: "/login"});
    }
  },
  props: {

  }
};

</script>

<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-5 col-xl-4" style="background-color: #f1f5f7">
        <div class="authentication-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">
          <div class="text-center">
            <div>
              <a href="/" class="logo">
                <img src="@/assets/images/workany-v-logo.png" height="130" width="180" alt="WorkAny"/>
              </a>
            </div>

            <h4 class="font-size-22 mt-3 mt-md-5">Email sent with password reset instructions</h4>
            <p class="text-muted">An email has been sent to</p>
            <h5 class="mt-3 mt-md-4">{{this.sentEmail}}</h5>
            <p> Hit "Reset Password" and you'll be ready to reset the password.</p>
            <div class="mt-3 mt-md-4 text-center">
              <p><u>
                <router-link
                    tag="a"
                    to="/login"
                    class="font-weight-medium text-primary"
                >Back to sign in
                </router-link>
              </u></p>
              <div class="mt-3 mt-md-4 text-center position-absolute fixed-bottom">
                <div style="border-bottom: 1px solid #ddd "></div>
                <p class="mt-3 mt-md-4 text-center">© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/" class="text-primary" target="_blank">W3
                  Engineers Ltd</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-8 d-none d-lg-block">
        <div class="min-vh-100 py-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/images/bg/verify-email.jpg" height="100%"  width ="52%" alt="password reset request"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color{
  background-color: #4B1FE5 !important;
}
</style>
